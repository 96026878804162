export type IUserStatus = {
  token?: string
  accountEmail?: string
  accountType?: 'Individual' | 'Entity'
  dictionary?: Record<
    string,
    {
      value: string
      text: string
      tip?: string | undefined
    }[]
  >
}
export const useSession = () => {
  // const store = useCookie<IUserStatus>('useUserStatusCookie')
  // const state = useState<IUserStatus>('useUserStatusState', () => {
  //   return store.value ?? {}
  // })
  const state = useState<IUserStatus>('useUserStatusState', () => {
    return {}
  })
  return {
    setAuthToken(token: string) {
      state.value = { ...state.value, token }
      // store.value = state.value
    },
    getAuthToken() {
      return state.value.token
    },
    setDictionary(dictionary: Record<string, Array<{ code: string; option: string; tip?: string }>>, t: (key: string) => string) {
      const obj: Record<string, Array<{ value: string; text: string; tip?: string }>> = {}
      if (dictionary) {
        Object.keys(dictionary).forEach((name) => {
          obj[name] = dictionary[name].map((item) => ({
            // text: name === 'currency' ? `${item.code} (${item.option})` : item.option,
            text: name === 'currency' ? `${item.code} (${t(item.option)})` : t(item.option),
            value: item.code,
            tip: item.tip,
          }))
        })
      }
      state.value = { ...state.value, dictionary: obj }
    },
    getDictionary() {
      return state.value.dictionary
    },
    setEmail(email: string) {
      state.value = { ...state.value, accountEmail: email }
    },
    getEmail() {
      return state.value.accountEmail
    },
    setAccountType(type: 'Individual' | 'Entity') {
      state.value = { ...state.value, accountType: type }
    },
    getAccountType() {
      return state.value.accountType
    },
  }
}

export const useRecaptchaPassed = () => {
  const {
    public: { enableDevTools },
  } = useRuntimeConfig()
  const state = useState<boolean>('useRecaptchaPassed', () => {
    return !!enableDevTools
  })
  return state
}
