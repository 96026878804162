import * as validators from '@vuelidate/validators'
import { phone } from 'phone'
import dayjs from 'dayjs'

export const useI18nlidate = () => {
  const i18n = useI18n()
  const { createI18nMessage, helpers } = validators

  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })

  const _phone = withI18nMessage(
    (val: IPhone) => {
      return !!phone(val.phone_number, {
        country: val.phone_country_code_short,
        validateMobilePrefix: false,
      }).isValid
    },
    {
      messagePath: () => 'validations.phone',
      messageParams: (params) => ({
        ...params,
      }),
    },
  )

  const numeric = (name?: string) =>
    withI18nMessage(validators.numeric, {
      messagePath: () => 'validations.numeric',
      messageParams: (params) => ({
        ...params,
        ...(name ? { property: name } : {}),
      }),
    })

  const alpha = (name?: string) =>
    withI18nMessage((value: string) => !value || /^[\sa-zA-Z]+$/.test(value), {
      messagePath: () => 'validations.alpha',
      messageParams: (params) => ({
        ...params,
        ...(name ? { property: name } : {}),
      }),
    })

  const telegram = withI18nMessage((value: string) => !value || value.startsWith('@'), {
    messagePath: () => 'telegram_error_message',
    messageParams: (params) => ({
      ...params,
    }),
  })

  const required = (name: string, path?: string) => {
    return withI18nMessage(validators.required, {
      messagePath: () => path ?? 'validations.required',
      messageParams: (params) => ({
        ...params,
        ...{ property: name ?? i18n.t('kyc.the_field') },
      }),
    })
  }

  const address = withI18nMessage(helpers.regex(/^((?![;:!?<>~`%^@{}|\\[\]'])[\u0021-\u007A\s])+$/))

  const email = withI18nMessage(validators.email)
  const minLength = withI18nMessage(validators.minLength, {
    withArguments: true,
  })
  const maxLength = withI18nMessage(validators.maxLength, {
    withArguments: true,
  })

  const minValue = withI18nMessage(validators.minValue, {
    withArguments: true,
    messagePath: () => 'validations.minValue',
    messageParams: (params) => ({
      ...params,
      ...{ property: i18n.t('kyc.your_share_percentage') },
    }),
  })

  const maxValue = (opt: Partial<Record<string, string>>, path?: string) =>
    withI18nMessage(validators.maxValue, {
      withArguments: true,
      messagePath: () => path ?? 'validations.maxValue',
      messageParams: (params) => ({
        ...params,
        ...opt,
      }),
    })

  const same = withI18nMessage(validators.sameAs, { withArguments: true })

  const maxArray = (name: string, len: number) =>
    withI18nMessage(
      (values: IFile[]) => {
        return !helpers.req(values) || values.length <= len
      },
      {
        messagePath: () => 'validations.maxArray',
        messageParams: (params) => ({
          ...params,
          max: len,
          ...{ property: name ?? i18n.t('kyc.the_field') },
        }),
      },
    )
  const requiredFiles = withI18nMessage(validators.required, {
    messagePath: () => 'validations.files',
  })

  const triggetYesFiles = withI18nMessage(
    (values: IFile[], context: any) => {
      return context.regulated_financial_institution === 'Yes' ? !!values.length : true
    },
    {
      messagePath: () => 'validations.files',
    },
  )

  const checkIdFile = (identityType: globalThis.Ref<'passport' | 'dl', 'passport' | 'dl'>) =>
    withI18nMessage(
      (files: IFile[]) => {
        const hasFiles = (files: IFile[]) => {
          const _files = files.filter((m) => m.file_type.includes(identityType.value))
          if (_files.length) {
            if (identityType.value === 'dl') {
              if (_files.some((m) => m.file_type.includes('dl_front')) && _files.some((m) => m.file_type.includes('dl_back'))) {
                return true
              } else {
                return false
              }
            }
            return true
          }
          return false
        }
        return hasFiles(files)
      },
      {
        messagePath: () => 'validations.files',
      },
    )

  const isAdult = withI18nMessage(
    (val: string) => {
      return !val || dayjs().isAfter(dayjs(val).add(18, 'years'))
    },
    {
      messagePath: () => 'validations.age',
    },
  )

  const triggetUS = (item: IAddress) =>
    withI18nMessage(
      (val: string, context: any) => {
        if (item.country === 'US') {
          return !!val
        }
        return true
      },
      {
        messagePath: () => 'validations.required',
        messageParams: (params) => ({
          ...params,
          ...{ property: i18n.t('kyc.the_field') },
        }),
      },
    )

  return {
    withAsync: helpers.withAsync,
    helpers,
    email,
    required,
    minLength,
    maxLength,
    same,
    phone: _phone,
    numeric,
    alpha,
    address,
    or: validators.or,
    withMessage: helpers.withMessage,
    minValue,
    maxValue,
    telegram,
    requiredFiles,
    maxArray,
    checkIdFile,
    triggetYesFiles,
    url: validators.url,
    isAdult,
    triggetUS
  }
}
